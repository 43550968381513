import React from 'react';
import './App.css';
import FishingLog from './components/FishingLog';


function App() {
    return (
        <div className="App">
            <div className="content">
                <FishingLog />
            </div>

        </div>
    );
}

export default App;
